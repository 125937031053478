import mmlogo2 from "../Media/mmlogo 2.png";
import prettyCake from "../Media/pretty cake.png";
import star from "../Media/star.png";
import curtains from "../Media/curtains.png";
import pillow1 from "../Media/pillow(1).png";
import pillow from "../Media/pillow.png";
import phonecase9 from "../Media/phonecase9.png";
import tufted from "../Media/tufted.png";
import tuftedtext from "../Media/tufted_text.gif";
import phonecase1 from "../Media/phonecase 1.png";
import maya from "../Media/maya.png";
import orderinfo from "../Media/orderinfo.gif";
import icinggirl from "../Media/icinggirl.gif";
import resinandtrinkets from "../Media/resinandtrinkets.gif";
import order from "../Media/order.gif";
import { Link } from "react-router-dom";
import BounceAnimation from "../Components/BounceAnimation";
import Image from "../Components/Image";
import Form from "../Components/Form";
import HoverImage from "../Components/HoverImage";
export default function Order(props: { isMobile: boolean }) {
  const offsetLeft = props.isMobile ? -90 : 0;
  const offsetTop = props.isMobile ? 260 : 0;
  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Image
        left={`${100 + offsetLeft}px`}
        top={`${10 + offsetTop}px`}
        height={"200px"}
        src={order}
      ></Image>
      <Image
        left={`${240 + offsetLeft * -0.9}px`}
        top={`${100 + offsetTop}px`}
        height={"200px"}
        src={orderinfo}
      ></Image>
      <div>
        <Link
          to="/"
          style={{
            position: "absolute",
            fontSize: "30px",
            color: "purple",
            fontWeight: "bold",
            zIndex: 99,
          }}
        >
          <div
            style={{
              left: props.isMobile ? "240px" : "700px",
              top: "20px",
            }}
            className="spinner"
          >
            <img src={star} alt="Spinning" />
          </div>
          <Image
            src={mmlogo2}
            left={props.isMobile ? "250px" : "710px"}
            top={"40px"}
            height={"200px"}
          ></Image>
        </Link>
      </div>
      <Image
        left={`${830 + offsetLeft * 7}px`}
        top={`${280 + offsetTop * 3.3}px`}
        height={"140px"}
        src={resinandtrinkets}
      ></Image>
      <Image
        left={`${600 + offsetLeft * 7}px`}
        top={`${280 + offsetTop * 3.3}px`}
        height={"140px"}
        src={icinggirl}
      ></Image>
      <Image
        left={`${1100 + offsetLeft * 7}px`}
        top={`${320 + offsetTop * 3.3}px`}
        height={"60px"}
        src={tuftedtext}
      ></Image>
      <Image
        left={`${650 + offsetLeft * 7}px`}
        top={`${380 + offsetTop * 3.3}px`}
        src={phonecase9}
        height={"300px"}
      ></Image>
      <Image
        left={`${840 + offsetLeft * 7}px`}
        top={`${380 + offsetTop * 3.3}px`}
        src={phonecase1}
        height={"290px"}
      ></Image>
      <Image
        left={`${1100 + offsetLeft * 7}px`}
        top={`${400 + offsetTop * 3.3}px`}
        src={tufted}
        height={"290px"}
      ></Image>
      ){!props.isMobile && <BounceAnimation src={star} />}
      {!props.isMobile && <BounceAnimation src={star} />}
      {!props.isMobile && <BounceAnimation src={star} />}
      {!props.isMobile && <BounceAnimation src={star} />}
      <BounceAnimation src={star} />
      <BounceAnimation src={star} />
      <BounceAnimation src={star} />
      <BounceAnimation src={star} />
      <BounceAnimation src={star} />
      <Form />
    </div>
  );
}
