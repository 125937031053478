import React, { useEffect, useRef, useState } from "react";
import "../style.css"; // Import the CSS file
import confetti from "../Media/confetti.gif";
import useIsMobile from "../Hooks/useIsMobile";
import promofound from "../Media/promofound.gif";

const BounceAnimation = (props: { src: string }) => {
  const isMobile = useIsMobile();
  const boxRef = useRef<HTMLDivElement>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [generatePromo, setGeneratePromo] = useState(false);

  const handleBoxClick = () => {
    if (Math.random() > 0.9) {
      setGeneratePromo(true);
    } else {
      setTimeout(() => setIsVisible(false), 400);
    }
    setIsClicked(true);
  };

  useEffect(() => {
    const box = boxRef.current;

    const bounce = () => {
      const containerWidth = window.innerWidth - 40;
      const containerHeight = window.innerHeight - 40;
      if (box != null) {
        const boxWidth = box.offsetWidth;
        const boxHeight = box.offsetHeight;

        let x = Math.random() * (containerWidth - boxWidth);
        let y = Math.random() * (containerHeight - boxHeight);
        let dx = 2 + Math.random() * (isMobile ? 2 : 4); // Speed in x direction
        let dy = 2 + Math.random() * (isMobile ? 2 : 4); // Speed in y direction

        const move = () => {
          if (x + dx > containerWidth - boxWidth || x + dx < 0) dx = -dx;
          if (y + dy > containerHeight - boxHeight || y + dy < 0) dy = -dy;

          x += dx;
          y += dy;
          box.style.transform = `translate(${x}px, ${y}px)`;

          requestAnimationFrame(move);
        };

        move();
      }
    };
    bounce();
  }, []);

  return (
    <div onClick={handleBoxClick} className="box" ref={boxRef}>
      {!generatePromo ? (
        isVisible && (
          <div className="spinner">
            <img
              style={{ width: isClicked ? "250px" : "100px" }}
              src={isClicked ? confetti : props.src}
              alt="Spinning"
            />
          </div>
        )
      ) : (
        <>
          <img
            style={{ width: "400px", top: "-80px", position: "absolute" }}
            src={confetti}
            alt="Spinning"
          />
          <img style={{ width: "400px" }} src={promofound} alt="Spinning" />
        </>
      )}
    </div>
  );
};

export default BounceAnimation;
