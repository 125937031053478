import "../style.css";
import React, { useState } from "react";
import bernHarn from "../Media/bern harn.png";
import mmlogo2 from "../Media/mmlogo 2.png";
import bow from "../Media/bow.png";
import cakeWithRed from "../Media/cake with  red.png";
import cake from "../Media/cake.png";
import frankMirror from "../Media/frank mirror.png";
import greenMirror from "../Media/green mirror.png";
import iWantToSuckYourBlood from "../Media/i want to suck ur blood.gif";
import phonecase1 from "../Media/phonecase 1.png";
import phonecase2 from "../Media/phonecase 2.png";
import phonecase3 from "../Media/phonecase3.png";
import phonecase4 from "../Media/phonecase4.png";
import phonecase5 from "../Media/phonecase5.png";
import phonecase6 from "../Media/phonecase6.png";
import phonecase7 from "../Media/phonecase7.png";
import phonecase8 from "../Media/phonecase8.png";
import phonecase9 from "../Media/phonecase9.png";
import prettyCake from "../Media/pretty cake.png";
import tulip from "../Media/tulip.png";
import cobweb from "../Media/cobweb.gif";
import star from "../Media/star.png";
import blueballoon from "../Media/blueballoon.png";
import yellowballoon from "../Media/yellowballoon.png";
import purpleballoon from "../Media/purpleballoon.png";
import flowers from "../Media/flowers.gif";
// import cuteCat from "../Media/cute cat.png";
// import curtains from "../Media/curtains.png";
// import pillow1 from "../Media/pillow(1).png";
// import pillow from "../Media/pillow.png";
// import plant from "../Media/plant.png";
// import pouf from "../Media/pouf .png";
import { Link } from "react-router-dom";
import BounceAnimation from "../Components/BounceAnimation";
import HoverImage from "../Components/HoverImage";
import biteme from "../Media/biteme.gif";
import ironicallyhot from "../Media/ironicallyhot.gif";
import orderlink from "../Media/orderlink.gif";
import Image from "../Components/Image";
import disco from "../Media/disco.gif";
export default function HomePage(props: { isMobile: boolean }) {
  // Define state to manage the background color
  const [bgColor, setBgColor] = useState<string>("white");

  // Handle click to toggle background color
  const handleClick = () => {
    setBgColor((prevColor) => (prevColor === "white" ? "black" : "white"));
  };
  const offsetLeft = props.isMobile ? -500 : 0;
  const offsetTop = props.isMobile ? 800 : 0;
  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        backgroundColor: bgColor,
        color: bgColor === "black" ? "white" : "black",
      }}
    >
      <Image src={biteme} left={"40px"} top={"400px"} height={"200px"}></Image>
      <Image src={biteme} left={"40px"} top={"480px"} height={"200px"}></Image>
      <Image src={biteme} left={"40px"} top={"560px"} height={"200px"}></Image>
      <Image src={biteme} left={"40px"} top={"640px"} height={"200px"}></Image>
      <Image src={biteme} left={"40px"} top={"720px"} height={"200px"}></Image>
      <Image src={biteme} left={"40px"} top={"800px"} height={"200px"}></Image>
      <Image
        left={`${900 + offsetLeft * 2}px`}
        top={`${20 + offsetTop * 2.1}px`}
        src={ironicallyhot}
        height={"200px"}
      ></Image>
      <Image
        left={`${940 + offsetLeft * 2}px`}
        top={`${100 + offsetTop * 2.1}px`}
        src={ironicallyhot}
        height={"200px"}
      ></Image>
      <Image
        left={`${980 + offsetLeft * 2}px`}
        top={`${180 + offsetTop * 2.1}px`}
        src={ironicallyhot}
        height={"200px"}
      ></Image>
      {!props.isMobile && <BounceAnimation src={blueballoon} />}
      <BounceAnimation src={blueballoon} />
      <BounceAnimation src={blueballoon} />
      {!props.isMobile && <BounceAnimation src={yellowballoon} />}
      <BounceAnimation src={yellowballoon} />
      {!props.isMobile && <BounceAnimation src={purpleballoon} />}
      {!props.isMobile && <BounceAnimation src={purpleballoon} />}
      <BounceAnimation src={purpleballoon} />
      <BounceAnimation src={purpleballoon} />
      <Image height={"300px"} src={flowers} left={"40px"} top={"0px"}></Image>

      {!props.isMobile && (
        <Image
          src={cobweb}
          left={"40px"}
          top={"400px"}
          height={"700px"}
        ></Image>
      )}
      <Image
        left={`${180 + offsetLeft * -0.5}px`}
        top={"130px"}
        height={"300px"}
        src={cakeWithRed}
      ></Image>
      <Image
        src={greenMirror}
        left={"40px"}
        top={"80px"}
        height={"300px"}
      ></Image>
      <div>
        <Link
          to="/about"
          style={{
            position: "absolute",
            left: "300px",
            top: "400px",
            fontSize: "30px",
            color: "purple",
            fontWeight: "bold",
            zIndex: 99,
          }}
        >
          about me
        </Link>
      </div>
      <Image height={"300px"} src={bernHarn} left={"0px"} top={"700px"}></Image>
      {!props.isMobile && (
        <Image
          height={"300px"}
          src={iWantToSuckYourBlood}
          left={"200px"}
          top={"800px"}
        ></Image>
      )}
      <div>
        <Link
          to="/"
          style={{
            position: "absolute",
            fontSize: "30px",
            color: "purple",
            fontWeight: "bold",
            zIndex: 99,
          }}
        >
          <div
            style={{
              left: props.isMobile ? "240px" : "700px",
              top: "20px",
            }}
            className="spinner"
          >
            <img src={star} alt="Spinning" />
          </div>
          <Image
            src={mmlogo2}
            left={props.isMobile ? "250px" : "710px"}
            top={"40px"}
            height={"200px"}
          ></Image>
        </Link>
      </div>
      <Image
        src={tulip}
        left={`${1000 + offsetLeft * 2}px`}
        top={`${20 + offsetTop * 2.4}px`}
        height={"550px"}
      />
      <Image
        src={bow}
        left={`${1200 + offsetLeft * 2}px`}
        top={`${200 + offsetTop * 2.4}px`}
        height={"200px"}
      />
      <Image
        src={frankMirror}
        left={`${1300 + offsetLeft * 2}px`}
        top={`${400 + offsetTop * 2.4}px`}
        height={"300px"}
      />
      <Image
        src={prettyCake}
        left={`${1150 + offsetLeft * 2}px`}
        top={`${650 + offsetTop * 2.4}px`}
        height={"300px"}
      />
      <Image
        src={iWantToSuckYourBlood}
        left={`${1300 + offsetLeft * 2}px`}
        top={`${-10 + offsetTop * 2.4}px`}
        height={"300px"}
      />
      <Image
        src={cake}
        left={`${600 + offsetLeft}px`}
        top={`${240 + offsetTop}px`}
        height={"800px"}
      />

      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${825 + offsetLeft}px`,
          top: `${280 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase8} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${700 + offsetLeft}px`,
          top: `${300 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase2} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${850 + offsetLeft}px`,
          top: `${410 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase3} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${800 + offsetLeft}px`,
          top: `${600 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase5} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${910 + offsetLeft}px`,
          top: `${660 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase4} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${800 + offsetLeft}px`,
          top: `${850 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase6} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${730 + offsetLeft}px`,
          top: `${450 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase9} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${675 + offsetLeft}px`,
          top: `${575 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase7} backgroundUrl={star} />
      </Link>
      <Link
        to="/order"
        style={{
          position: "absolute",
          left: `${700 + offsetLeft}px`,
          top: `${700 + offsetTop}px`,
          zIndex: 99,
        }}
      >
        <HoverImage foregroundUrl={phonecase1} backgroundUrl={star} />
      </Link>
      <div>
        <Link
          to="/order"
          style={{
            position: "absolute",
            left: `${700 + offsetLeft * 0.95}px`,
            top: `${910 + offsetTop * 0.1}px`,
            fontSize: "30px",
            color: "black",
            fontWeight: "bold",
            zIndex: 99,
          }}
        >
          <img
            style={{
              width: props.isMobile ? "240px" : "160px",
            }}
            src={orderlink}
            alt=""
          />
        </Link>
      </div>
      {!props.isMobile && (
        <div
          style={{
            position: "absolute",
            top: "500px",
            left: "500px",
            zIndex: 99,
          }}
          onClick={handleClick}
        >
          <img
            src={disco}
            style={{
              width: "140px",
            }}
            alt=""
          />
        </div>
      )}
    </div>
  );
}
