import useMousePosition from "../Hooks/useMousePosition";
import cursor from "../Media/cursor.png";

const Cursor = () => {
  const { x, y } = useMousePosition();
  return (
    <>
      {/* 2. */}
      <div
        style={{
          position: "absolute",
          left: `${x}px`,
          top: `${y}px`,
          pointerEvents: "none",
        }}
      >
        <img
          style={{
            position: "absolute",
            width: "80px",
            zIndex: 99,
            left: "-20px",
            top: "-20px",
            pointerEvents: "none",
          }}
          src={cursor}
          alt="d"
        />
      </div>
    </>
  );
};

export default Cursor;
