import mmlogo2 from "../Media/mmlogo 2.png";
import prettyCake from "../Media/pretty cake.png";
import star from "../Media/star.png";
import curtains from "../Media/curtains.png";
import pillow1 from "../Media/pillow(1).png";
import pillow from "../Media/pillow.png";
import maya from "../Media/maya.png";
import crayon from "../Media/crayon.png";
import { Link } from "react-router-dom";
import BounceAnimation from "../Components/BounceAnimation";
import Image from "../Components/Image";
import blueballoon from "../Media/blueballoon.png";
import instagram from "../Media/instagram.gif";
import purpleballoon from "../Media/purpleballoon.png";
import useIsMobile from "../Hooks/useIsMobile";
export default function AboutPage(props: { isMobile: boolean }) {
  const offsetLeft = props.isMobile ? -800 : 0;
  const offsetTop = props.isMobile ? 800 : 0;
  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div>
        {!props.isMobile && <BounceAnimation src={blueballoon} />}
        {props.isMobile && <BounceAnimation src={blueballoon} />}
        <BounceAnimation src={blueballoon} />
        {!props.isMobile && <BounceAnimation src={purpleballoon} />}
        <BounceAnimation src={purpleballoon} />
        <BounceAnimation src={purpleballoon} />
        <BounceAnimation src={purpleballoon} />
      </div>

      {!props.isMobile && (
        <Image
          height={"300px"}
          src={prettyCake}
          left={"100px"}
          top={"800px"}
        ></Image>
      )}
      <Image
        left={`${750 + offsetLeft}px`}
        top={`${100 + offsetTop}px`}
        height={"600px"}
        src={crayon}
      ></Image>
      <Image
        left={`${1000 + offsetLeft}px`}
        top={`${215 + offsetTop}px`}
        height={"420px"}
        src={maya}
      ></Image>
      <Image
        left={`${1000 + offsetLeft}px`}
        top={`${200 + offsetTop}px`}
        height={"300px"}
        src={curtains}
      ></Image>
      <Image
        left={`${900 + offsetLeft}px`}
        top={`${500 + offsetTop}px`}
        height={"200px"}
        src={pillow1}
      ></Image>
      <Image
        left={`${1100 + offsetLeft}px`}
        top={`${500 + offsetTop}px`}
        height={"300px"}
        src={pillow}
      ></Image>
      <header
        style={{
          lineHeight: "85%",
          position: "absolute",
          fontWeight: "bold",
          fontSize: "230px",
          zIndex: 99,
          top: `${0 + offsetTop * 0.23}px`,
        }}
      >
        Maya <br></br>Is <br></br>a
      </header>
      {!props.isMobile && (
        <header
          style={{
            position: "absolute",
            fontWeight: "bold",
            fontSize: "60px",
            zIndex: 99,
            left: `${1200 + offsetLeft}px`,
            top: `${100 + offsetTop}px`,
          }}
        >
          me
        </header>
      )}
      <div
        style={{
          position: "absolute",
          left: "220px",
          top: `${260 + offsetTop * 0.23}px`,
          width: "400px",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "bold", lineHeight: "150%" }}>
          creator who finds inspiration in everyday objects, integrating them
          into her art to give them new meaning. Her art comes from the
          combination of and destruction of gifted thrifted and stolen trinkets
          and textiles. Maya's work is an extension of herself, blending her
          personal struggles and experiences with her outward political beliefs
          and actions. Known for disregarding traditional beauty standards and
          rules, she easily weaves her way through multitudes of mixed media
          projects combining ceramics, paper mache, fiber, cloth, resin, yarn,
          found items, paint, and tufting. Inspired by drag, theater, the south,
          the mundane, and the extraordinary, her art is a living paradox that
          you simply can't take your eyes off of. You can find her art scattered
          across this website, at the West Coast Craft Fair in Fort Mason August
          24th, and on her instagram
          <a href="https://www.instagram.com/mayamiracle__/"> @mayamiracle__</a>
        </p>
      </div>
      <div>
        <Link
          to="/"
          style={{
            position: "absolute",
            fontSize: "30px",
            color: "purple",
            fontWeight: "bold",
            zIndex: 99,
          }}
        >
          <div
            style={{
              left: props.isMobile ? "240px" : "700px",
              top: "20px",
            }}
            className="spinner"
          >
            <img src={star} alt="Spinning" />
          </div>
          <Image
            src={mmlogo2}
            left={props.isMobile ? "250px" : "710px"}
            top={"40px"}
            height={"200px"}
          ></Image>
        </Link>
      </div>
    </div>
  );
}
