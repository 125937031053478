import "../style.css"; // Import the CSS file
import React from "react";
// Define the props type
interface HoverImageProps {
  foregroundUrl: string;
  backgroundUrl: string;
}
const HoverImage: React.FC<HoverImageProps> = ({
  foregroundUrl,
  backgroundUrl,
}) => {
  return (
    <div className="hover-container">
      <img alt="" src={foregroundUrl} className="hover-content" />
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          animation: "spin 3s linear infinite",
        }}
      ></div>
    </div>
  );
};

export default HoverImage;
