import React from "react";
import HomePage from "./Pages/HomePage";
import useIsMobile from "./Hooks/useIsMobile";
import AboutPage from "./Pages/AboutPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../src/style.css";
import Cursor from "./Components/Cursor";
import Order from "./Pages/Order";
export default function App() {
  const isMobile = useIsMobile();
  console.log(isMobile);
  return (
    <>
      {!isMobile && <Cursor />}
      <Router>
        <Switch>
          <Route path="/order" exact={true}>
            <Order isMobile={isMobile} />
          </Route>
          <Route path="/" exact={true}>
            <HomePage isMobile={isMobile} />
          </Route>
          <Route path="/about" exact={true}>
            <AboutPage isMobile={isMobile} />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
