export default function Image(props: {
  src: string;
  left: string;
  top: string;
  height?: string;
}) {
  return (
    <div
      style={{
        left: props.left,
        top: props.top,
        height: props.height ? props.height : "400px",
        position: "absolute",
        zIndex: 99,
      }}
    >
      <img
        src={props.src}
        style={{ height: props.height ? props.height : "400px" }}
        alt={""}
      />
    </div>
  );
}
