import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import useIsMobile from "../Hooks/useIsMobile";

const Form: React.FC = () => {
  const isMobile = useIsMobile();
  const offsetLeft = isMobile ? -80 : 0;
  const offsetTop = isMobile ? 200 : 0;
  const [phoneSize, setPhoneSize] = useState("");
  const [selection, setSelection] = useState("");
  const [version, setVersion] = useState("");
  const [promo, setPromo] = useState("");
  const [personName, setPersonName] = useState("");
  const [human, setHuman] = useState("");
  const [vibeDescription, setVibeDescription] = useState("");
  const form = useRef<HTMLFormElement | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (human === "hooman") {
      // Normally, here you would handle form submission, e.g., send the data to an API or an email service.
      alert(
        `SUBMITTING ORDER:\nPhone Size: ${phoneSize}\nProduct: ${selection}\nStyle: ${version}\nVibe Description: ${vibeDescription}`
      );
      if (form.current) {
        emailjs
          .sendForm("contact_service", "template_3r4ua35", form.current, {
            publicKey: "-aDMCAa6-5O3UvL3m",
          })
          .then(
            () => {
              alert("SUCCESS :))))) !!!");
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
      }
    } else {
      alert("No robbits, try writing 'hooman'");
    }
  };

  return (
    <form
      ref={form}
      onSubmit={handleSubmit}
      style={{
        fontSize: "20px",
        position: "absolute",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          right: "20px",
          height: "200px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="selection"
          style={{
            position: "absolute",
            top: `${275 + offsetTop}px`,
            width: "200px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Product
        </label>
        <select
          name="selection"
          id="selection"
          value={selection}
          onChange={(e) => setSelection(e.target.value)}
          required={true}
          style={{
            position: "absolute",
            top: `${300 + offsetTop}px`,
            width: "200px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <option value="">Select...</option>
          <option value="phone case">Phone Case</option>
          <option value="pop socket">Pop Socket</option>
        </select>
      </div>
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          right: "20px",
          height: "200px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="version"
          style={{
            position: "absolute",
            top: `${340 + offsetTop}px`,
            width: "200px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Style
        </label>
        <select
          name="version"
          id="version"
          required={true}
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          style={{
            position: "absolute",
            top: `${365 + offsetTop}px`,
            width: "200px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <option value="">Select...</option>
          <option value="resin">Trinkets & Resin</option>
          <option value="icing">Icing Girl</option>
          <option value="tufted">Tufted</option>
        </select>
      </div>
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          height: "200px",
          right: "20px",
          width: "400px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="phoneSize"
          style={{
            position: "absolute",
            top: `${405 + offsetTop}px`,
            height: "100px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Phone Size
        </label>
        <input
          name="phoneSize"
          id="phoneSize"
          type="text"
          placeholder="ex: iphone 7"
          value={phoneSize}
          onChange={(e) => setPhoneSize(e.target.value)}
          style={{
            position: "absolute",
            top: `${430 + offsetTop}px`,
            width: "100%",
            height: "40px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          right: "20px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="vibeDescription"
          style={{
            position: "absolute",
            top: `${500 + offsetTop}px`,
            width: "500px",
            left: `${220 + offsetLeft}px`,
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Vibe in one sentence or 5 emojis
        </label>
        <input
          name="vibeDescription"
          id="vibeDescription"
          required={true}
          value={vibeDescription}
          onChange={(e) => setVibeDescription(e.target.value)}
          style={{
            position: "absolute",
            top: `${525 + offsetTop}px`,
            width: "400px",
            left: `${220 + offsetLeft}px`,
            height: "50px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            resize: "vertical",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          height: "200px",
          right: "20px",
          width: "400px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="promo"
          style={{
            position: "absolute",
            top: `${600 + offsetTop}px`,
            height: "100px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          PROMO CODE (optional)
        </label>
        <input
          name="promo"
          id="promo"
          type="text"
          placeholder="have you tried popping a balloon.....?"
          value={promo}
          onChange={(e) => setPromo(e.target.value)}
          style={{
            position: "absolute",
            top: `${625 + offsetTop}px`,
            width: "100%",
            height: "40px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          height: "200px",
          right: "20px",
          width: "400px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="name"
          style={{
            position: "absolute",
            top: `${700 + offsetTop}px`,
            height: "100px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Email & Name
        </label>
        <input
          name="name"
          id="name"
          required={true}
          type="text"
          placeholder="ex: Jon Smith jjsmitty@yayoo.com"
          value={personName}
          onChange={(e) => setPersonName(e.target.value)}
          style={{
            position: "absolute",
            top: `${725 + offsetTop}px`,
            width: "100%",
            height: "40px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: `${200 + offsetLeft}px`,
          height: "200px",
          right: "20px",
          width: "400px",
          marginBottom: "20px",
        }}
      >
        <label
          htmlFor="human"
          style={{
            position: "absolute",
            top: `${800 + offsetTop}px`,
            height: "100px",
            display: "block",
            marginBottom: "8px",
            fontWeight: "bold",
          }}
        >
          Write 'hooman' below
        </label>
        <input
          name="human"
          id="human"
          type="text"
          required={true}
          value={human}
          onChange={(e) => setHuman(e.target.value)}
          style={{
            position: "absolute",
            top: `${825 + offsetTop}px`,
            width: "100%",
            height: "40px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
      </div>
      <button
        type="submit"
        style={{
          position: "absolute",
          top: `${900 + offsetTop}px`,
          padding: "10px 20px",
          left: `${350 + offsetLeft}px`,
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Submit
      </button>
    </form>
  );
};

export default Form;
